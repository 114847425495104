import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="clear-header page-padding max-viewport mx-auto py-6">
        <p className="text-2xl uppercase font-bold text-center tablet:text-3xl">Scanners detect 404</p>
        <div className="tablet:flex">
            <div className="flex tablet:w-1/2 justify-around items-center">
                <Link
                    to="/"
                    className="bg-white text-black px-12 py-2 font-bold mb-6"
                >
                    Report for Maintenance
                </Link>
            </div>
            <div className="flex w-full justify-around tablet:w-1/2">
                <StaticImage
                    alt="R2D2"
                    src="../images/r2d2.png"
                    width={420}
                />
            </div>
        </div>
    </div>
  </Layout>
)

export default NotFoundPage
